<template>
  <section>
    <two-panel
      :leftPanelWidth="'1050'"
      :rightPanelWidth="'337'"
      :leftPanelTitle="leftPanelTitle"
      :rightPanelTitle="rightPanelTitle"
    >
      <template #leftPanel>
        <!-------------- 회원가입이력 목록 -------------->
        <table-list-component
          :tableHeight="'603'"
          ref="tableListComponent"
          :headers="headers"
          :items="itemsWithIndex"
          :nowPerPage="nowPerPage"
          :totalRecords="totalRecords"
          :totalPages="totalPages"
          :searchOpts="searchOpts"
          :customSlotInfo="customSlotInfo"
          :loading="loading"
          @rowClick="rowClick"
          @getDataFromApi="getHisList"
        >
          <!-------------- 검색 옵션 -------------->
          <template #searchOptions>
            <label class="sort-label"
              >· {{ $t('service.history.search-condition1') }}</label
            >
            <input
              v-model="searchOpts.userId"
              type="text"
              class="sort-input-text"
              maxlength="20"
              style="width:50px;"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />
            <label class="sort-label"
              >· {{ $t('service.history.search-condition2') }}</label
            >
            <input
              v-model="searchOpts.userNm"
              type="text"
              class="sort-input-text"
              maxlength="30"
              style="width:50px;"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />
            <label class="sort-label">· </label>
            <select
              v-model="searchOpts.srchCondition"
              class="sort-input-text"
              style="width:100px;"
            >
              <option value="srvcNm">{{
                $t('service.history.search-condition3')
              }}</option>
              <option value="srvcId">{{
                $t('service.history.search-condition4')
              }}</option>
            </select>
            <input
              v-model="searchOpts.srchData"
              type="text"
              class="sort-input-text ml-1"
              style="width:50px"
              maxlength="30"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />
            <label class="sort-label"
              >· {{ $t('service.history.search-condition5') }}</label
            >
            <span class="daterange-wrap">
              <vc-date-picker
                v-model="searchOpts.srbdeDtRange"
                mode="date"
                :columns="$screens({ default: 1, lg: 2 })"
                is-range
                color="orange"
                :locale="$i18n.locale"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <input
                    type="text"
                    class="sort-input-text"
                    style="width: 170px;"
                    id="srbdeDaterangepicker"
                    :value="
                      inputValue.start != null
                        ? `${inputValue.start} ~ ${inputValue.end}`
                        : null
                    "
                    autocomplete="off"
                    @click="togglePopover()"
                    @keyup.enter="keyUpEnter"
                    @keydown="inputLiteralCheck"
                  />
                  <label for="srbdeDaterangepicker" class="ic-calendar"></label>
                </template>
              </vc-date-picker>
            </span>
            <label class="sort-label"
              >· {{ $t('service.history.search-condition6') }}</label
            >
            <span class="daterange-wrap">
              <vc-date-picker
                v-model="searchOpts.trmnatDtRange"
                mode="date"
                :columns="$screens({ default: 1, lg: 2 })"
                is-range
                color="orange"
                :locale="$i18n.locale"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <input
                    type="text"
                    class="sort-input-text"
                    style="width: 170px;"
                    id="trmnateDaterangepicker"
                    :value="
                      inputValue.start != null
                        ? `${inputValue.start} ~ ${inputValue.end}`
                        : null
                    "
                    autocomplete="off"
                    @click="togglePopover()"
                    @keyup.enter="keyUpEnter"
                    @keydown="inputLiteralCheck"
                  />
                  <label
                    for="trmnateDaterangepicker"
                    class="ic-calendar"
                  ></label>
                </template>
              </vc-date-picker>
            </span>
          </template>
          <!-- 길이 초과될 거 같은 컬럼의 경우, title 입력.. mouseOver 시 텍스트 표시됨 -->
          <template v-slot:srvcNm_custom="{ item }">
            <td class="text-center td-ellipsis" :title="item.srvcNm">
              {{ item.srvcNm || '-' }}
            </td>
          </template>
        </table-list-component>
      </template>
      <!-------------- 상세 정보 -------------->
      <template #rightPanel>
        <table-tab-component
          :tabItems="tabItems"
          :tabOverlay="tabOverlay"
          :tableHeight="'710'"
        >
          <!-- custom slot -->
          <template v-slot:trmnatPrvonsh_tab_custom="{ item }">
            <td>
              <div style="height:100px; overflow-y:auto;">
                {{ item.value || '-' }}
              </div>
            </td>
          </template>
        </table-tab-component>
      </template>
    </two-panel>
  </section>
</template>
<script>
import TwoPanel from '@/layouts/TwoPanel.vue';
import TableListComponent from '@/components/TableListComponent';
import TableTabComponent from '@/components/TableTabComponent';
import mixins from '@/mixins/CommonMixins';
import { getHistoryList } from '@/api/serviceApi';

export default {
  mixins: [mixins],
  components: {
    TwoPanel,
    TableListComponent,
    TableTabComponent,
  },
  data: () => ({
    // --- 테이블 --- //
    loading: false,
    // --- 검색 정보 --- //
    searchOpts: {
      userId: '',
      userNm: '',
      srchCondition: 'srvcNm',
      srchData: '',
      srbdeDtRange: '',
      trmnatDtRange: '',
    },
    // --- Total row 개수 --- //
    totalRecords: 0,
    totalPages: 0,
    tableItems: [],
    nowPerPage: 30,
    // --- custom Slot --- //
    customSlotInfo: [{ name: 'srvcNm', slotName: 'item.srvcNm' }],
    // -- 우측 패널 테이블 -- //
    tabOverlay: false,
    tabItems: [],
  }),
  created() {
    this.tabItems = this.tabItemsComputed;
  },
  methods: {
    // 회원가입이력 목록 조회
    async getHisList(searchParams) {
      this.loading = true;
      const {
        rows,
        page,
        sord,
        sidx,
        userId,
        userNm,
        srchCondition,
        srchData,
        srbdeDtRange,
        trmnatDtRange,
      } = searchParams;
      const params = {
        // 리스트 표출건수
        rows,
        // 요청페이지
        page,
        // 정렬 컬럼
        sidx,
        // 정렬
        sord,
        userId: userId.trim(),
        userNm: userNm.trim(),
        srchData: srchData.trim(),
        srchCondition: srchCondition,
        startDateString: srbdeDtRange.start,
        endDateString: srbdeDtRange.end,
        startTrDateString: trmnatDtRange.start,
        endTrDateString: trmnatDtRange.end,
      };
      await getHistoryList(params)
        .then(res => {
          const { rows, totalRecords, totalPages } = res.data;
          this.tableItems = rows;
          this.totalRecords = totalRecords;
          this.totalPages = totalPages;
        })
        .catch(error => {
          console.log('error', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // row 클릭 시 상세 조회
    rowClick(item) {
      this.tabOverlay = true;
      const keys = Object.keys(this.tabItems[0].selectedItem);
      keys.forEach(key => {
        if (key == 'srvcNm') {
          let serviceTxt = '';
          serviceTxt = item['srvcNm'] + '(' + item['srvcId'] + ')';
          this.tabItems[0].selectedItem[key].value = serviceTxt;
        } else {
          this.tabItems[0].selectedItem[key].value = item[key];
        }
      });
      this.tabOverlay = false;
    },
    // 검색
    keyUpEnter() {
      this.$refs.tableListComponent.search();
    },
  },
  computed: {
    itemsWithIndex() {
      return this.tableItems.map((tableItems, index) => ({
        ...tableItems,
        srbdeDt: this.getFormatDate(this.tableItems[index].srbdeDt),
        trmnatDt: this.getFormatDate(this.tableItems[index].trmnatDt),
      }));
    },
    leftPanelTitle() {
      return this.$i18n.t('service.history.leftPanel.title');
    },
    headers() {
      return [
        {
          text: this.$i18n.t('service.history.leftPanel.table-column1'),
          value: 'userId',
          width: '20%',
        },
        {
          text: this.$i18n.t('service.history.leftPanel.table-column2'),
          value: 'userNm',
          width: '20%',
        },
        {
          text: this.$i18n.t('service.history.leftPanel.table-column3'),
          value: 'srvcNm',
          width: '15%',
        },
        {
          text: this.$i18n.t('service.history.leftPanel.table-column4'),
          value: 'srvcId',
          width: '15%',
        },
        {
          text: this.$i18n.t('service.history.leftPanel.table-column5'),
          value: 'srbdeDt',
          width: '20%',
        },
        {
          text: this.$i18n.t('service.history.leftPanel.table-column6'),
          value: 'trmnatDt',
          width: '20%',
        },
      ];
    },
    rightPanelTitle() {
      return this.$i18n.t('service.history.rightPanel.title');
    },
    tabItemsComputed() {
      return [
        {
          headers: [
            {
              text: this.$i18n.t('service.history.rightPanel.header1'),
              width: '40%',
            },
            {
              text: this.$i18n.t('service.history.rightPanel.header2'),
              width: '60%',
            },
          ],
          selectedItem: {
            userId: {
              name: this.$i18n.t('service.history.rightPanel.label1'),
              value: '',
            },
            userNm: {
              name: this.$i18n.t('service.history.rightPanel.label2'),
              value: '',
            },
            srvcNm: {
              name: this.$i18n.t('service.history.rightPanel.label3'),
              value: '',
            },
            srbdeDt: {
              name: this.$i18n.t('service.history.rightPanel.label4'),
              value: '',
            },
            trmnatDt: {
              name: this.$i18n.t('service.history.rightPanel.label5'),
              value: '',
            },
            trmnatPrvonsh: {
              name: this.$i18n.t('service.history.rightPanel.label6'),
              value: '',
              isCustom: true,
            },
          },
        },
      ];
    },
    lang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    lang() {
      this.tabItems = this.tabItemsComputed;
    },
  },
};
</script>
<style lang="scss" scoped></style>
