<template>
  <div>
    <two-panel
      :leftPanelWidth="'820'"
      :rightPanelWidth="'567'"
      :leftPanelTitle="leftPanelTitle"
      :rightPanelTitle="rightPanelTitle"
    >
      <template #leftPanel>
        <table-list-component
          ref="tableListComponent"
          :tableHeight="'603'"
          :headers="headers"
          :nowPerPage="nowPerPage"
          :items="alarmRuleList"
          :totalRecords="totalRecords"
          :totalPages="totalPages"
          :customSlotInfo="customSlotInfo"
          :searchOpts="searchAlarmRule"
          :loading="isTableOverlay"
          :reloadToggle="reloadToggle"
          @rowClick="rowClick"
          @getDataFromApi="doGetRuleList"
        >
          <template #searchOptions>
            <label for="search_alarmRuleId" class="sort-label">
              · {{ $t('service.rule.search-condition1') }}
            </label>
            <input
              type="text"
              id="search_alarmRuleId"
              class="sort-input-text"
              v-model="searchAlarmRule.alarmRuleId"
              maxlength="20"
              @keydown.enter="doSearch()"
            />
            <label for="search_alarmItemCode" class="sort-label">
              · {{ $t('service.rule.search-condition2') }}
            </label>
            <select
              id="search_alarmItemCode"
              class="sort-input-text "
              style="width:110px;"
              v-model="searchAlarmRule.alarmItemCode"
            >
              <option value="">{{
                $t('common.select', { target: '항목' })
              }}</option>
              <option
                v-for="ruleAlarmItem in ruleAlarmItems"
                :key="ruleAlarmItem.code"
                :value="ruleAlarmItem.code"
              >
                {{ ruleAlarmItem.name }}
              </option>
            </select>
            <label for="search_crtrId" class="sort-label">
              · {{ $t('service.rule.search-condition3') }}
            </label>
            <input
              type="text"
              id="search_crtrId"
              class="sort-input-text"
              v-model="searchAlarmRule.crtrId"
              maxlength="20"
              @keydown.enter="doSearch()"
            />
          </template>
          <template #subHeaderItem>
            <input
              type="button"
              class="bt-line24 cr-orange"
              :value="btnRegister"
              @click="isOpenInsertPopUp = true"
            />
          </template>
          <template v-slot:alarmRuleNm_custom="{ item }">
            <td class="text-center td-ellipsis" :title="item.alarmRuleNm">
              {{ item.alarmRuleNm }}
            </td>
          </template>
          <template v-slot:creatDt_custom="{ item }">
            <td class="text-center">
              {{ item.creatDt !== '-' ? getFormatDate(item.creatDt) : '-' }}
            </td>
          </template>
        </table-list-component>
      </template>
      <template #rightPanel>
        <table-tab-component
          :tableHeight="'710'"
          :tabOverlay="isTabOverlay"
          :tabItems="tabItems"
        >
          <template #alarmRule_tab_custom="{item}">
            <td class="alarm-rule-tab" style="padding: 0px !important;">
              <div style="height: 158px">
                <table class="table-style-default">
                  <colgroup>
                    <col width="56px" />
                    <col width="100px" />
                    <col width="44px" />
                    <col width="50px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th></th>
                      <th>{{ $t('service.rule.rightPanel.subHeader1') }}</th>
                      <th>{{ $t('service.rule.rightPanel.subHeader2') }}</th>
                      <th>{{ $t('service.rule.rightPanel.subHeader3') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(rule, index) in item.value" :key="index">
                      <td>
                        {{
                          `${$t('service.rule.rightPanel.label14')} ${index +
                            1}`
                        }}
                      </td>
                      <td>{{ rule.name }}</td>
                      <td>{{ rule.scopeCode }}</td>
                      <td>{{ rule.ruleValue }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </template>

          <template #alarmMsgCn_tab_custom="{item}">
            <td>
              <div style="height: 75px; overflow-y:auto;">
                <span>
                  {{ item.value ? item.value : '-' }}
                </span>
              </div>
            </td>
          </template>

          <template #creatDt_tab_custom="{item}">
            <td>
              {{
                item.value && item.value !== '-'
                  ? getFormatDate(item.value)
                  : '-'
              }}
            </td>
          </template>

          <template #updtDt_tab_custom="{item}">
            <td>
              {{
                item.value && item.value !== '-'
                  ? getFormatDate(item.value)
                  : '-'
              }}
            </td>
          </template>

          <template #alarmRuleInfo_bottom_button>
            <div class="table-bot-btns">
              <p class="f-right">
                <input
                  type="button"
                  :value="btnUpdate"
                  class="bt-line24 cr-gray"
                  @click="openUpdatePopUp()"
                />
                <input
                  type="button"
                  :value="btnDelete"
                  class="bt-line24 cr-gray"
                  @click="doDeleteRule()"
                />
              </p>
            </div>
          </template>
        </table-tab-component>
      </template>
    </two-panel>

    <rule-pop-up
      ref="insertRulePopUp"
      :ruleAlarmItems="ruleAlarmItems"
      :type="'insert'"
      :isOpen="isOpenInsertPopUp"
      :alarmRuleInfo="insertAlarmRuleInfo"
      :srvcList="srvcList"
      @close="closeInsertPopUp"
      @confirm="doInsertRule"
    ></rule-pop-up>

    <rule-pop-up
      ref="updateRulePopUp"
      :ruleAlarmItems="ruleAlarmItems"
      :type="'update'"
      :isOpen="isOpenUpdatePopUp"
      :alarmRuleInfo="updateAlarmRuleInfo"
      :srvcList="srvcList"
      @close="closeUpdatePopUp"
      @confirm="doUpdateRule"
    ></rule-pop-up>
  </div>
</template>

<script>
import TwoPanel from '@/layouts/TwoPanel.vue';
import TableListComponent from '@/components/TableListComponent.vue';
import TableTabComponent from '@/components/TableTabComponent.vue';
import RulePopUp from '@/views/service/rule/RulePopUp.vue';
import {
  getRuleList,
  insertRule,
  updateRule,
  deleteRule,
} from '@/api/serviceApi';
import CommonMixins from '@/mixins/CommonMixins';
import { getCommonCode } from '@/api/commonApi';
import { COMMON_GROUP_CODE } from '@/store/properties';
import { getOperatorSrvcList } from '@/api/operationApi';

export default {
  data() {
    return {
      customSlotInfo: [
        {
          name: 'alarmRuleNm',
          slotName: 'item.alarmRuleNm',
        },
        {
          name: 'creatDt',
          slotName: 'item.creatDt',
        },
      ],
      nowPerPage: 30,
      alarmRuleList: [],
      totalRecords: 0,
      totalPages: 0,
      searchAlarmRule: {
        alarmRuleId: '',
        alarmItemCode: '',
        crtrId: '',
      },
      isTableOverlay: false,
      reloadToggle: true,
      isTabOverlay: false,
      tabItems: [],
      clickedRow: {},
      isOpenInsertPopUp: false,
      insertAlarmRuleInfo: {
        alarmRuleId: '',
        alarmRuleNm: '',
        srvcId: '',
        ruleList: [{ alarmItemCode: '', scopeCode: '', ruleValue: '' }],
        genderCode: 'M',
        alarmTyCode: 'AND',
        alarmCycleValue: 0,
        alarmMsgCn: '',
      },
      isOpenUpdatePopUp: false,
      updateAlarmRuleInfo: {
        alarmRuleSn: 0,
        alarmRuleId: '',
        alarmRuleNm: '',
        srvcId: '',
        ruleList: [],
        genderCode: '',
        alarmTyCode: '',
        alarmCycleValue: 0,
        alarmMsgCn: '',
      },
      ruleAlarmItems: [],
      srvcList: [],
    };
  },
  mixins: [CommonMixins],
  components: {
    TwoPanel,
    TableListComponent,
    TableTabComponent,
    RulePopUp,
  },
  created() {
    this.tabItems = this.tabItemsComputed;
    this.getCommonCode();
    this.doGetOperatorSrvcList();
  },
  methods: {
    async doGetOperatorSrvcList() {
      try {
        const { data: srvcList } = await getOperatorSrvcList();
        this.srvcList = srvcList;
      } catch (error) {
        console.log('error', error);
      }
    },
    async getCommonCode() {
      this.ruleAlarmItems = [];
      const { data: healthCodes } = await getCommonCode(
        COMMON_GROUP_CODE.CODE_RULE_HEALTH_CODE,
      );

      healthCodes.forEach(obj => {
        const code = obj.codeName;
        const name = obj.description;
        this.ruleAlarmItems.push({ code, name });
      });
    },
    async doGetRuleList(searchParams) {
      this.doResetClicked();

      const {
        page,
        rows,
        sidx,
        sord,
        alarmRuleId,
        alarmItemCode,
        crtrId,
      } = searchParams;

      const reqObj = {
        page,
        rows,
        sidx,
        sord,
        alarmRuleId: alarmRuleId.trim(),
        alarmItemCode,
        crtrId: crtrId.trim(),
      };

      try {
        this.isTableOverlay = true;
        const { data } = await getRuleList(reqObj);
        const { rows: items, totalPages, totalRecords } = data;
        this.alarmRuleList = items;
        this.totalPages = totalPages;
        this.totalRecords = totalRecords;
      } catch (error) {
        console.log('error', error);
      } finally {
        this.isTableOverlay = false;
      }
    },
    rowClick(item) {
      this.clickedRow = item;
      this.doGetRuleDetail();
    },
    doGetRuleDetail() {
      this.isTabOverlay = true;
      const alarmRuleInfoTabItems = this.tabItems[0].selectedItem;
      const {
        alarmRuleId,
        alarmRuleNm,
        srvcId,
        ruleDtlVOList,
        alarmTyCode,
        ruleTyCode,
        alarmCycleValue,
        alarmMsgCn,
        crtrId,
        creatDt,
        updusrId,
        updtDt,
      } = this.clickedRow;

      alarmRuleInfoTabItems.alarmRuleId.value = alarmRuleId;
      alarmRuleInfoTabItems.alarmRuleNm.value = alarmRuleNm;
      alarmRuleInfoTabItems.srvcId.value = srvcId;
      alarmRuleInfoTabItems.alarmCycleValue.value = alarmCycleValue;
      alarmRuleInfoTabItems.alarmMsgCn.value = alarmMsgCn;
      alarmRuleInfoTabItems.crtrId.value = crtrId;
      alarmRuleInfoTabItems.creatDt.value = creatDt;
      alarmRuleInfoTabItems.updusrId.value = updusrId;
      alarmRuleInfoTabItems.updtDt.value = updtDt;

      let alarmTyCodeNm;
      switch (alarmTyCode) {
        case '&&': {
          alarmTyCodeNm = this.$i18n.t('service.rule.popUp.alarmType-label1');
          break;
        }
        case '||': {
          alarmTyCodeNm = this.$i18n.t('service.rule.popUp.alarmType-label2');
          break;
        }
        default: {
          alarmTyCodeNm = '-';
          break;
        }
      }
      alarmRuleInfoTabItems.alarmTyCode.value = alarmTyCodeNm;

      let ruleTyCodeNm;
      switch (ruleTyCode) {
        case 'ALARM': {
          ruleTyCodeNm = this.$i18n.t('service.rule.rightPanel.ruleType1');
          break;
        }
        case 'FEED': {
          ruleTyCodeNm = this.$i18n.t('service.rule.rightPanel.ruleType2');
          break;
        }
        default: {
          ruleTyCodeNm = '-';
          break;
        }
      }
      alarmRuleInfoTabItems.ruleTyCode.value = ruleTyCodeNm;

      let ruleList = [];
      let genderCode;
      ruleDtlVOList.forEach(ruleDtl => {
        const { alarmItemCode, scopeCode, ruleValue } = ruleDtl;
        if (alarmItemCode === 'gender') {
          genderCode = ruleValue;
        } else {
          this.ruleAlarmItems.forEach(ruleAlarmItem => {
            const { code, name } = ruleAlarmItem;
            if (alarmItemCode === code) {
              ruleList.push({ name, scopeCode, ruleValue });
            }
          });
        }
      });
      alarmRuleInfoTabItems.alarmRule.value = ruleList;
      alarmRuleInfoTabItems.genderCode.value =
        genderCode === 'M'
          ? this.$i18n.t('service.rule.popUp.gender-label1')
          : this.$i18n.t('service.rule.popUp.gender-label2');
      this.isTabOverlay = false;
    },
    doSearch() {
      this.$refs.tableListComponent.search();
    },
    doResetClicked() {
      this.doResetTab();
      this.clickedRow = {};
    },
    doResetTab() {
      const alarmRuleInfoTabItems = this.tabItems[0].selectedItem;
      const keys = Object.keys(alarmRuleInfoTabItems);
      keys.forEach(key => {
        const item = alarmRuleInfoTabItems[key];
        if (key === 'alarmRule') {
          item.value = [];
        } else {
          item.value = '';
        }
      });
    },
    closeInsertPopUp() {
      this.isOpenInsertPopUp = false;
      this.$refs.insertRulePopUp.doReset();
      this.insertAlarmRuleInfo = {
        alarmRuleId: '',
        alarmRuleNm: '',
        srvcId: '',
        ruleList: [{ alarmItemCode: '', scopeCode: '', ruleValue: '' }],
        genderCode: 'M',
        alarmTyCode: 'AND',
        alarmCycleValue: 0,
        alarmMsgCn: '',
      };
    },
    async doInsertRule() {
      const valid = this.validateAlarmRuleInfo(this.insertAlarmRuleInfo);
      if (!valid) {
        return;
      }

      const {
        alarmRuleId,
        srvcId,
        alarmRuleNm,
        genderCode,
        alarmTyCode,
        alarmMsgCn,
        alarmCycleValue,
        ruleList,
      } = this.insertAlarmRuleInfo;

      const ruleDtlVOList = [
        ...ruleList,
        { alarmItemCode: 'gender', scopeCode: '==', ruleValue: genderCode },
      ];

      const reqObj = {
        alarmRuleId,
        srvcId,
        alarmRuleNm: alarmRuleNm.trim(),
        alarmTyCode: alarmTyCode === 'AND' ? '&&' : '||',
        ruleTyCode: 'ALARM',
        alarmMsgCn: alarmMsgCn.trim(),
        alarmCycleValue: parseInt(alarmCycleValue),
        ruleDtlVOList: ruleDtlVOList,
      };

      try {
        const { data } = await insertRule(reqObj);
        const { valid } = data;
        if (valid) {
          this.$dialogs
            .alert(this.$i18n.t('common.alert.insert-success'))
            .then(() => {
              this.closeInsertPopUp();
              this.reloadToggle = !this.reloadToggle;
            });
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    validateAlarmRuleInfo(alarmRuleInfo) {
      const { srvcId, ruleList } = alarmRuleInfo;
      if (!srvcId) {
        const target = this.getPostWord(
          this.$i18n.t('service.rule.target-service'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return false;
      }

      let idx;
      let rulePass = true;
      for (idx = 1; idx <= ruleList.length; idx++) {
        const rule = ruleList[idx - 1];
        const { alarmItemCode, scopeCode, ruleValue } = rule;
        if (!alarmItemCode || !scopeCode || !ruleValue) {
          rulePass = false;
          break;
        }
      }

      if (!rulePass) {
        this.$dialogs.alert(
          this.$i18n.t('common.alert.invalid-condition', { conditionIdx: idx }),
        );

        return false;
      }

      return true;
    },
    openUpdatePopUp() {
      if (this.isEmptyObject(this.clickedRow)) {
        const target = this.getPostWord(
          this.$i18n.t('service.rule.target-rule'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return;
      }

      const {
        alarmRuleSn,
        alarmRuleId,
        alarmRuleNm,
        srvcId,
        ruleDtlVOList,
        alarmTyCode,
        alarmCycleValue,
        alarmMsgCn,
      } = this.clickedRow;

      this.updateAlarmRuleInfo.alarmRuleSn = alarmRuleSn;
      this.updateAlarmRuleInfo.alarmRuleId = alarmRuleId;
      this.updateAlarmRuleInfo.alarmRuleNm = alarmRuleNm;
      this.updateAlarmRuleInfo.srvcId = srvcId;
      this.updateAlarmRuleInfo.alarmCycleValue = alarmCycleValue;
      this.updateAlarmRuleInfo.alarmMsgCn = alarmMsgCn;

      let alarmTyCodeNm;
      switch (alarmTyCode) {
        case '&&': {
          alarmTyCodeNm = 'AND';
          break;
        }
        case '||': {
          alarmTyCodeNm = 'OR';
          break;
        }
        default: {
          alarmTyCodeNm = '';
          break;
        }
      }
      this.updateAlarmRuleInfo.alarmTyCode = alarmTyCodeNm;

      let ruleList = [];
      let genderCode;
      ruleDtlVOList.forEach(ruleDtl => {
        const { alarmItemCode, scopeCode, ruleValue } = ruleDtl;
        if (alarmItemCode === 'gender') {
          genderCode = ruleValue;
        } else {
          ruleList.push({ alarmItemCode, scopeCode, ruleValue });
        }
      });
      this.updateAlarmRuleInfo.genderCode = genderCode;
      this.updateAlarmRuleInfo.ruleList = ruleList;

      this.isOpenUpdatePopUp = true;
    },
    async doUpdateRule() {
      const valid = this.validateAlarmRuleInfo(this.updateAlarmRuleInfo);
      if (!valid) {
        return;
      }

      const {
        alarmRuleSn,
        alarmRuleId,
        alarmRuleNm,
        srvcId,
        ruleList,
        genderCode,
        alarmTyCode,
        alarmCycleValue,
        alarmMsgCn,
      } = this.updateAlarmRuleInfo;

      const ruleDtlVOList = [
        ...ruleList,
        { alarmItemCode: 'gender', scopeCode: '==', ruleValue: genderCode },
      ];

      const reqObj = {
        alarmRuleSn,
        alarmRuleId,
        alarmRuleNm: alarmRuleNm.trim(),
        srvcId,
        alarmTyCode: alarmTyCode === 'AND' ? '&&' : '||',
        ruleTyCode: 'ALARM',
        alarmCycleValue: parseInt(alarmCycleValue),
        alarmMsgCn: alarmMsgCn.trim(),
        ruleDtlVOList: ruleDtlVOList,
      };

      try {
        const { data } = await updateRule(reqObj);
        const { valid } = data;
        if (valid) {
          this.$dialogs
            .alert(this.$i18n.t('common.alert.update-success'))
            .then(() => {
              this.closeUpdatePopUp();
              this.reloadToggle = !this.reloadToggle;
            });
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    closeUpdatePopUp() {
      this.isOpenUpdatePopUp = false;
      this.$refs.updateRulePopUp.doReset();
      this.updateAlarmRuleInfo = {
        alarmRuleSn: 0,
        alarmRuleId: '',
        alarmRuleNm: '',
        srvcId: '',
        ruleList: [],
        genderCode: '',
        alarmTyCode: '',
        alarmCycleValue: 0,
        alarmMsgCn: '',
      };
    },
    async doDeleteRule() {
      if (this.isEmptyObject(this.clickedRow)) {
        const target = this.getPostWord(
          this.$i18n.t('service.rule.target-rule'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return;
      }

      const { alarmRuleSn, alarmRuleId } = this.clickedRow;

      const confirmText = `<p>Alarm Rule ID: <span class="c-orange">${alarmRuleId}</span></p>${this.$i18n.t(
        'common.alert.delete-confirm',
      )}`;
      const { ok } = await this.$dialogs.confirm(confirmText);
      if (ok) {
        try {
          const { data } = await deleteRule(alarmRuleSn);
          const { valid } = data;
          if (valid) {
            this.$dialogs
              .alert(this.$i18n.t('common.alert.delete-success'))
              .then(() => {
                this.reloadToggle = !this.reloadToggle;
              });
          }
        } catch (error) {
          console.log('error', error);
        }
      }
    },
  },
  computed: {
    leftPanelTitle() {
      return this.$i18n.t('service.rule.leftPanel.title');
    },
    headers() {
      return [
        {
          text: this.$i18n.t('service.rule.leftPanel.table-column1'),
          value: 'alarmRuleId',
          width: '20%',
          sortable: false,
        },
        {
          text: this.$i18n.t('service.rule.leftPanel.table-column2'),
          value: 'alarmRuleNm',
          width: '26%',
          sortable: false,
        },
        {
          text: this.$i18n.t('service.rule.leftPanel.table-column3'),
          value: 'srvcId',
          width: '17%',
        },
        {
          text: this.$i18n.t('service.rule.leftPanel.table-column4'),
          value: 'creatDt',
          width: '22%',
        },
        {
          text: this.$i18n.t('service.rule.leftPanel.table-column5'),
          value: 'crtrId',
          width: '15%',
        },
      ];
    },
    rightPanelTitle() {
      return this.$i18n.t('service.rule.rightPanel.title');
    },
    tabItemsComputed() {
      return [
        {
          id: 'alarmRuleInfo',
          headers: [
            {
              text: this.$i18n.t('service.rule.rightPanel.header1'),
              width: '30%',
            },
            {
              text: this.$i18n.t('service.rule.rightPanel.header2'),
              width: '70%',
            },
          ],
          selectedItem: {
            alarmRuleId: {
              name: this.$i18n.t('service.rule.rightPanel.label1'),
              value: '',
            },
            alarmRuleNm: {
              name: this.$i18n.t('service.rule.rightPanel.label2'),
              value: '',
            },
            srvcId: {
              name: this.$i18n.t('service.rule.rightPanel.label3'),
              value: '',
            },
            alarmRule: {
              name: this.$i18n.t('service.rule.rightPanel.label4'),
              value: [],
              isCustom: true,
            },
            genderCode: {
              name: this.$i18n.t('service.rule.rightPanel.label5'),
              value: '',
            },
            alarmTyCode: {
              name: this.$i18n.t('service.rule.rightPanel.label6'),
              value: '',
            },
            ruleTyCode: {
              name: this.$i18n.t('service.rule.rightPanel.label7'),
              value: '',
            },
            alarmCycleValue: {
              name: this.$i18n.t('service.rule.rightPanel.label8'),
              value: '',
            },
            alarmMsgCn: {
              name: this.$i18n.t('service.rule.rightPanel.label9'),
              value: '',
              isCustom: true,
            },
            crtrId: {
              name: this.$i18n.t('service.rule.rightPanel.label10'),
              value: '',
            },
            creatDt: {
              name: this.$i18n.t('service.rule.rightPanel.label11'),
              value: '',
              isCustom: true,
            },
            updusrId: {
              name: this.$i18n.t('service.rule.rightPanel.label12'),
              value: '',
            },
            updtDt: {
              name: this.$i18n.t('service.rule.rightPanel.label13'),
              value: '',
              isCustom: true,
            },
          },
        },
      ];
    },
    lang() {
      return this.$i18n.locale;
    },
    btnRegister() {
      return this.$i18n.t('common.btn.register');
    },
    btnUpdate() {
      return this.$i18n.t('common.btn.update');
    },
    btnDelete() {
      return this.$i18n.t('common.btn.delete');
    },
  },
  watch: {
    lang() {
      this.tabItems = this.tabItemsComputed;
      this.getCommonCode();
    },
  },
};
</script>

<style scoped>
.bt-line24 {
  margin: 1.5px;
}

.alarm-rule-tab {
  overflow: auto;
}

.alarm-rule-tab table {
  border-top: 1px solid #ccc;
}

.alarm-rule-tab thead th {
  background: #f6f6f6 !important;
  text-align: left;
}
</style>
