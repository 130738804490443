<template>
  <div>
    <two-panel
      :left-panel-width="'820'"
      :right-panel-width="'567'"
      :left-panel-title="leftPanelTitle"
      :right-panel-title="rightPanelTitle"
    >
      <template #leftPanel>
        <table-list-component
          ref="tableListComponent"
          :table-height="'603'"
          :headers="headers"
          :items="subscriptionList"
          :now-per-page="nowPerPage"
          :loading="isTableOverlay"
          :custom-slot-info="customSlotInfo"
          :search-opts="searchSubscription"
          :total-records="totalRecords"
          :total-pages="totalPages"
          :reload-toggle="reloadToggle"
          @rowClick="rowClick"
          @getDataFromApi="doGetSubscriptionList"
        >
          <template #searchOptions>
            <label for="search_srvcId" class="sort-label">
              · {{ $t('service.subscription.search-condition1') }}
            </label>
            <select
              class="sort-input-text"
              style="width: 160px"
              v-model="searchSubscription.srvcId"
            >
              <option value="">{{
                $t('common.select', { target: '항목' })
              }}</option>
              <option
                v-for="(srvc, index) in srvcs"
                :key="index"
                :value="srvc.srvcId"
              >
                {{ srvc.srvcNm }}
              </option>
            </select>
          </template>
          <template #subHeaderItem>
            <input
              type="button"
              class="bt-line24 cr-orange"
              :value="btnRegister"
              @click="doOpenInsertPopUp()"
            />
          </template>
          <template #subscriptionDetails_custom="{ item }">
            <td
              class="text-center td-ellipsis"
              :title="getSubConditionByList(item.subscriptionDetails)"
            >
              {{ getSubConditionByList(item.subscriptionDetails) }}
            </td>
          </template>
        </table-list-component>
      </template>
      <template #rightPanel>
        <table-tab-component
          :table-height="'710'"
          :tab-overlay="isTabOverlay"
          :tab-items="tabItems"
        >
          <template #subscriptionDetails_tab_custom="{item}">
            <td class="sub__condition__tab" style="padding: 0px !important;">
              <div style="height: 158px">
                <table class="table-style-default">
                  <colgroup>
                    <col width="100px" />
                    <col width="150px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>
                        {{ $t('service.subscription.rightPanel.subHeader1') }}
                      </th>
                      <th>
                        {{ $t('service.subscription.rightPanel.subHeader2') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(subscription, index) in item.value"
                      :key="index"
                    >
                      <td>{{ subscription.healthCodeName }}</td>
                      <td>{{ subscription.endDate }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </template>

          <template #subscriptionInfo_bottom_button>
            <div class="table-bot-btns">
              <p class="f-right">
                <input
                  type="button"
                  :value="btnUpdate"
                  class="bt-line24 cr-gray"
                  @click="doOpenUpdatePopUp()"
                />
                <input
                  type="button"
                  :value="btnDelete"
                  class="bt-line24 cr-gray"
                  @click="doDeleteSubscription()"
                />
              </p>
            </div>
          </template>
        </table-tab-component>
      </template>
    </two-panel>

    <subscription-pop-up
      ref="insertSubscriptionPopUp"
      :is-open="isOpenInsertPopUp"
      :pop-up-mode="popUpMode"
      @close="doClosePopUp"
      :subscription-info="insertSubscriptionInfo"
      @addRow="addRow"
      @deleteRow="deleteRow"
      :srvcs="srvcs"
      :subscription-health-codes="subscriptionHealthCodes"
      @confirm="doConfirmPopUp"
    ></subscription-pop-up>

    <subscription-pop-up
      ref="updateSubscriptionPopUp"
      :is-open="isOpenUpdatePopUp"
      :pop-up-mode="popUpMode"
      @close="doClosePopUp"
      :subscription-info="updateSubscriptionInfo"
      @addRow="addRow"
      @deleteRow="deleteRow"
      :srvcs="srvcs"
      :subscription-health-codes="subscriptionHealthCodes"
      @confirm="doConfirmPopUp"
    ></subscription-pop-up>
  </div>
</template>

<script>
import TwoPanel from '@/layouts/TwoPanel.vue';
import TableListComponent from '@/components/TableListComponent.vue';
import TableTabComponent from '@/components/TableTabComponent.vue';
import {
  getSubscriptionList,
  deleteSubscription,
  insertSubscription,
  updateSubscription,
} from '@/api/serviceApi';
import CommonMixins from '@/mixins/CommonMixins';
import SubscriptionPopUp from '@/views/service/subscription/SubscriptionPopUp.vue';
import { getCommonCode } from '@/api/commonApi';
import { COMMON_GROUP_CODE } from '@/store/properties';
import { getOperatorSrvcList } from '@/api/operationApi';

export default {
  data() {
    return {
      customSlotInfo: [
        {
          name: 'subscriptionDetails',
          slotName: 'item.subscriptionDetails',
        },
      ],
      subscriptionList: [],
      nowPerPage: 30,
      isTableOverlay: false,
      reloadToggle: false,
      totalRecords: 0,
      totalPages: 0,
      searchSubscription: {
        srvcId: '',
      },
      clickedRow: {},
      tabItems: [],
      isTabOverlay: false,
      popUpMode: '',
      isOpenInsertPopUp: false,
      insertSubscriptionInfo: {
        srvcId: '',
        subscriptionDetails: [
          {
            criteria: '',
            endDate: '',
          },
        ],
        pushRedirectUrl: '',
      },
      subscriptionListSrvcs: [],
      isOpenUpdatePopUp: false,
      updateSubscriptionInfo: {
        srvcId: '',
        subscriptionDetails: [],
        pushRedirectUrl: '',
        resourceIdList: [],
      },
      subscriptionHealthCodes: [],
      srvcs: [],
    };
  },
  components: {
    TwoPanel,
    TableListComponent,
    TableTabComponent,
    SubscriptionPopUp,
  },
  mixins: [CommonMixins],
  created() {
    this.tabItems = this.tabItemsComputed;
    this.getCommonCode();
    this.getOperatorSrvcList();
  },
  methods: {
    async getCommonCode() {
      const { data: subscriptionHealthCodes } = await getCommonCode(
        COMMON_GROUP_CODE.CODE_SUBSCRIPTION_HEALTH_CODE,
      );

      this.subscriptionHealthCodes = subscriptionHealthCodes;
    },
    async getOperatorSrvcList() {
      const { data: srvcs } = await getOperatorSrvcList();
      this.srvcs = srvcs;
    },
    async doGetSubscriptionList(searchParams) {
      this.doResetClicked();
      const { page, rows, sidx, sord, srvcId } = searchParams;

      const reqObj = {
        page,
        rows,
        sidx,
        sord,
        srvcId,
      };

      try {
        this.isTableOverlay = true;
        const { data } = await getSubscriptionList(reqObj);
        const { rows: items, totalPages, totalRecords } = data;
        items.forEach(item => {
          const { srvcId } = item;
          this.subscriptionListSrvcs.push(srvcId);
        });
        this.subscriptionList = items;
        this.totalPages = totalPages;
        this.totalRecords = totalRecords;
      } catch (error) {
        console.log('error', error);
      } finally {
        this.isTableOverlay = false;
      }
    },
    getSubConditionByList(subscriptionDetails) {
      let subCondtions = [];
      subscriptionDetails.forEach(subscriptionDetail => {
        const { criteria } = subscriptionDetail;
        this.subscriptionHealthCodes.forEach(subscriptionHealthCode => {
          const { codeName, description } = subscriptionHealthCode;
          if (criteria.indexOf(codeName) > -1) {
            subCondtions.push(description);
          }
        });
      });

      if (subCondtions.length < 1) {
        return '-';
      } else {
        return subCondtions.join(', ');
      }
    },
    doSearch() {
      this.$refs.tableListComponent.search();
    },
    rowClick(item) {
      this.clickedRow = item;
      this.doGetSubscriptionDetail();
    },
    doGetSubscriptionDetail() {
      this.isTabOverlay = true;
      const subscriptionInfoTabItems = this.tabItems[0].selectedItem;
      const { srvcId, pushRedirectUrl, subscriptionDetails } = this.clickedRow;
      let subscriptionDetailList = [];

      subscriptionInfoTabItems.srvcId.value = srvcId;
      subscriptionInfoTabItems.pushRedirectUrl.value = pushRedirectUrl;

      subscriptionDetails.forEach(subscriptionDetail => {
        const { criteria, endDate } = subscriptionDetail;
        this.subscriptionHealthCodes.forEach(subscriptionHealthCode => {
          const { codeName, description } = subscriptionHealthCode;
          if (criteria.indexOf(codeName) > -1) {
            subscriptionDetailList.push({
              healthCodeName: description,
              endDate,
            });
          }
        });
      });
      subscriptionInfoTabItems.subscriptionDetails.value = subscriptionDetailList;

      this.isTabOverlay = false;
    },
    doResetClicked() {
      this.doResetTab();
      this.clickedRow = {};
    },
    doResetTab() {
      const subscriptionInfoTabItems = this.tabItems[0].selectedItem;
      const keys = Object.keys(subscriptionInfoTabItems);
      keys.forEach(key => {
        const item = subscriptionInfoTabItems[key];
        if (key === 'subscriptionDetails') {
          item.value = [];
        } else {
          item.value = '';
        }
      });
    },
    async doDeleteSubscription() {
      if (this.isEmptyObject(this.clickedRow)) {
        const target = this.getPostWord(
          this.$i18n.t('service.subscription.target-subscription'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return false;
      }

      const { srvcId, subscriptionDetails } = this.clickedRow;

      const confirmMessage = `<p>${this.$i18n.t(
        'service.subscription.target-serviceId',
      )} : <span class="c-orange">${srvcId}</span></p>${this.$i18n.t(
        'common.alert.delete-confirm',
      )}`;
      const { ok } = await this.$dialogs.confirm(confirmMessage);
      if (ok) {
        let resourceIdList = [];
        subscriptionDetails.forEach(subscriptionDetail => {
          const { resourceId } = subscriptionDetail;
          resourceIdList.push(resourceId);
        });

        if (resourceIdList.length < 1) {
          const target = this.getPostWord(
            this.$i18n.t('service.subscription.target-subscription'),
            '이',
            '가',
          );
          this.$dialogs.alert(
            this.$i18n.t('common.alert.target-empty', { target }),
          );
          return false;
        }

        const resourceIds = resourceIdList.join(',');

        try {
          this.isTabOverlay = true;
          const { data } = await deleteSubscription(resourceIds);
          const { message } = data;
          this.$dialogs.alert(message).then(() => {
            this.reloadToggle = !this.reloadToggle;
          });
        } catch (error) {
          console.log('error', error);
        } finally {
          this.isTabOverlay = false;
        }
      }
    },
    doOpenInsertPopUp() {
      this.isOpenInsertPopUp = true;
      this.popUpMode = 'insert';
    },
    async doUpdateSubscription() {
      const reqObj = this.updateSubscriptionInfo;

      try {
        const { data } = await updateSubscription(reqObj);
        const { message } = data;
        this.$dialogs.alert(message).then(() => {
          this.doClosePopUp('update');
          this.reloadToggle = !this.reloadToggle;
        });
      } catch (error) {
        console.log('error', error);
      }

      console.log('reqObj', reqObj);
    },
    doOpenUpdatePopUp() {
      if (this.isEmptyObject(this.clickedRow)) {
        const target = this.getPostWord(
          this.$i18n.t('service.subscription.target-subscription'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return false;
      }

      const { srvcId, subscriptionDetails, pushRedirectUrl } = this.clickedRow;
      this.updateSubscriptionInfo.srvcId = srvcId;
      this.updateSubscriptionInfo.pushRedirectUrl = pushRedirectUrl;

      if (subscriptionDetails.length > 0) {
        subscriptionDetails.forEach(subscriptionDetail => {
          console.log('subscriptionDetail', subscriptionDetail);
          const { resourceId, criteria, endDate } = subscriptionDetail;
          let healthCode;
          for (let i in this.subscriptionHealthCodes) {
            const subscriptionHealthCode = this.subscriptionHealthCodes[i];
            const { codeName } = subscriptionHealthCode;
            if (criteria.indexOf(codeName) > -1) {
              healthCode = codeName;
              break;
            }
          }
          this.updateSubscriptionInfo.subscriptionDetails.push({
            criteria: healthCode,
            endDate,
          });
          this.updateSubscriptionInfo.resourceIdList.push(resourceId);
        });
      } else {
        this.updateSubscriptionInfo.subscriptionDetails.push({
          criteria: '',
          endDate: '',
        });
      }

      this.isOpenUpdatePopUp = true;
      this.popUpMode = 'update';
    },
    doClosePopUp(popUpMode) {
      if (popUpMode === 'insert') {
        this.isOpenInsertPopUp = false;
        this.$refs.insertSubscriptionPopUp.doReset();
        this.insertSubscriptionInfo = {
          srvcId: '',
          subscriptionDetails: [
            {
              criteria: '',
              endDate: '',
            },
          ],
          pushRedirectUrl: '',
        };
      } else {
        this.isOpenUpdatePopUp = false;
        this.$refs.updateSubscriptionPopUp.doReset();
        this.updateSubscriptionInfo = {
          srvcId: '',
          subscriptionDetails: [],
          pushRedirectUrl: '',
          resourceIdList: [],
        };
      }
    },
    addRow(popUpMode) {
      if (popUpMode === 'insert') {
        this.insertSubscriptionInfo.subscriptionDetails.push({
          criteria: '',
          endDate: '',
        });
      } else {
        this.updateSubscriptionInfo.subscriptionDetails.push({
          criteria: '',
          endDate: '',
        });
      }
    },
    deleteRow(index, popUpMode) {
      if (popUpMode === 'insert') {
        this.insertSubscriptionInfo.subscriptionDetails.splice(index, 1);
      } else {
        this.updateSubscriptionInfo.subscriptionDetails.splice(index, 1);
      }
    },
    async doInsertSubscription() {
      const {
        srvcId,
        subscriptionDetails,
        pushRedirectUrl,
      } = this.insertSubscriptionInfo;

      if (this.subscriptionListSrvcs.indexOf(srvcId) > -1) {
        this.$dialogs.alert(this.$i18n.t('common.alert.invalid-service-dupl'));
        return false;
      }

      const reqObj = {
        srvcId,
        subscriptionDetails,
        pushRedirectUrl,
      };

      try {
        const { data } = await insertSubscription(reqObj);
        const { message } = data;
        this.$dialogs.alert(message).then(() => {
          this.doClosePopUp('insert');
          this.reloadToggle = !this.reloadToggle;
        });
      } catch (error) {
        console.log('error', error);
      }
    },
    doConfirmPopUp(popUpMode) {
      if (popUpMode === 'insert') {
        this.doInsertSubscription();
      } else {
        this.doUpdateSubscription();
      }
    },
  },
  computed: {
    leftPanelTitle() {
      return this.$i18n.t('service.subscription.leftPanel.title');
    },
    headers() {
      return [
        {
          text: this.$i18n.t('service.subscription.leftPanel.table-column1'),
          value: 'srvcId',
          width: '30%',
          sortable: false,
        },
        {
          text: this.$i18n.t('service.subscription.leftPanel.table-column2'),
          value: 'subscriptionDetails',
          width: '30%',
          sortable: false,
        },
        {
          text: this.$i18n.t('service.subscription.leftPanel.table-column3'),
          value: 'pushRedirectUrl',
          width: '40%',
          sortable: false,
        },
      ];
    },
    rightPanelTitle() {
      return this.$i18n.t('service.subscription.rightPanel.title');
    },
    tabItemsComputed() {
      return [
        {
          id: 'subscriptionInfo',
          headers: [
            {
              text: this.$i18n.t('service.subscription.rightPanel.header1'),
              width: '30%',
            },
            {
              text: this.$i18n.t('service.subscription.rightPanel.header2'),
              width: '70%',
            },
          ],
          selectedItem: {
            srvcId: {
              name: this.$i18n.t('service.subscription.rightPanel.label1'),
              value: '',
            },
            subscriptionDetails: {
              name: this.$i18n.t('service.subscription.rightPanel.label2'),
              value: [],
              isCustom: true,
            },
            pushRedirectUrl: {
              name: this.$i18n.t('service.subscription.rightPanel.label3'),
              value: '',
            },
          },
        },
      ];
    },
    lang() {
      return this.$i18n.locale;
    },
    btnRegister() {
      return this.$i18n.t('common.btn.register');
    },
    btnUpdate() {
      return this.$i18n.t('common.btn.update');
    },
    btnDelete() {
      return this.$i18n.t('common.btn.delete');
    },
  },
  watch: {
    lang() {
      this.tabItems = this.tabItemsComputed;
      this.getCommonCode();
    },
  },
};
</script>

<style scoped>
.bt-line24 {
  margin: 1.5px;
}
.sub__condition__tab {
  overflow: auto;
}

.sub__condition__tab table {
  border-top: 1px solid #ccc;
}

.sub__condition__tab thead th {
  background: #f6f6f6 !important;
  text-align: left;
}
</style>
