<template>
  <section>
    <two-panel
      :leftPanelWidth="'1050'"
      :rightPanelWidth="'337'"
      :leftPanelTitle="leftPanelTitle"
      :rightPanelTitle="rightPanelTitle"
    >
      <template #leftPanel>
        <!-------------- 회원 목록 -------------->
        <table-list-component
          :tableHeight="'603'"
          ref="tableListComponent"
          :headers="headers"
          :items="itemsWithIndex"
          :nowPerPage="nowPerPage"
          :totalRecords="totalRecords"
          :totalPages="totalPages"
          :searchOpts="searchOpts"
          :loading="loading"
          :customSlotInfo="customSlotInfo"
          @rowClick="rowClick"
          @getDataFromApi="getUserSrchList"
        >
          <!-------------- 검색 옵션 -------------->
          <template #searchOptions>
            <label for="search_userId" class="sort-label"
              >· {{ $t('service.user.search-condition1') }}</label
            >
            <input
              id="search_userId"
              v-model="searchOpts.userId"
              type="text"
              class="sort-input-text"
              style="width:100px"
              maxlength="20"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />
            <label for="search_userNm" class="sort-label"
              >· {{ $t('service.user.search-condition2') }}</label
            >
            <input
              id="search_userNm"
              v-model="searchOpts.userNm"
              type="text"
              class="sort-input-text"
              style="width:100px"
              maxlength="30"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />
            <label for="search_srvcSttus" class="sort-label">· </label>
            <select
              id="search_srvcSttus"
              v-model="searchOpts.srvcSttus"
              class="sort-input-text "
              style="width:100px;"
            >
              <option value="srvcNm">{{
                $t('service.user.search-condition3')
              }}</option>
              <option value="srvcId">{{
                $t('service.user.search-condition4')
              }}</option>
            </select>
            <input
              id="search_srchData"
              v-model="searchOpts.srchData"
              type="text"
              class="sort-input-text ml-1"
              style="width:100px"
              maxlength="30"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />
            <label class="sort-label"
              >· {{ $t('service.user.search-condition5') }}</label
            >
            <span class="daterange-wrap">
              <vc-date-picker
                v-model="searchOpts.dateRange"
                mode="date"
                :columns="$screens({ default: 1, lg: 2 })"
                is-range
                color="orange"
                :locale="$i18n.locale"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <input
                    type="text"
                    class="sort-input-text"
                    style="width: 170px;"
                    id="findDateDaterangepicker"
                    :value="
                      inputValue.start != null
                        ? `${inputValue.start} ~ ${inputValue.end}`
                        : null
                    "
                    autocomplete="off"
                    @click="togglePopover()"
                    @keyup.enter="keyUpEnter"
                    @keydown="inputLiteralCheck"
                  />
                  <label
                    for="findDateDaterangepicker"
                    class="ic-calendar"
                  ></label>
                </template>
              </vc-date-picker>
            </span>
          </template>
          <!-- 길이 초과될 거 같은 컬럼의 경우, title 입력.. mouseOver 시 텍스트 표시됨 -->
          <template v-slot:srvcNm_custom="{ item }">
            <td class="text-center td-ellipsis" :title="item.srvcNm">
              {{ item.srvcNm || '-' }}
            </td>
          </template>
        </table-list-component>
      </template>
      <!-------------- 상세 정보 -------------->
      <template #rightPanel>
        <table-tab-component
          :tabItems="tabItems"
          :tabOverlay="tabOverlay"
          :tableHeight="'710'"
        >
        </table-tab-component>
      </template>
    </two-panel>
  </section>
</template>
<script>
import TwoPanel from '@/layouts/TwoPanel.vue';
import TableListComponent from '@/components/TableListComponent';
import TableTabComponent from '@/components/TableTabComponent';
import mixins from '@/mixins/CommonMixins';
import { getUserList, getUserDetail } from '@/api/serviceApi';

export default {
  mixins: [mixins],
  components: {
    TwoPanel,
    TableListComponent,
    TableTabComponent,
  },
  data: () => ({
    // --- 테이블 --- //
    loading: false,
    // --- 검색 정보 --- //
    searchOpts: {
      userId: '',
      userNm: '',
      srvcSttus: 'srvcNm',
      srchData: '',
      dateRange: '',
    },
    // --- Total row 개수 --- //
    totalRecords: 0,
    totalPages: 0,
    tableItems: [],
    nowPerPage: 30,
    // --- custom Slot --- //
    customSlotInfo: [{ name: 'srvcNm', slotName: 'item.srvcNm' }],
    // -- 우측 패널 테이블 -- //
    tabOverlay: false,
  }),
  methods: {
    // 회원 목록 조회
    async getUserSrchList(searchParams) {
      this.loading = true;
      const {
        rows,
        page,
        sord,
        sidx,
        userId,
        userNm,
        srvcSttus,
        srchData,
        dateRange,
      } = searchParams;
      const params = {
        // 리스트 표출건수
        rows,
        // 요청페이지
        page,
        // 정렬 컬럼
        sidx,
        // 정렬
        sord,
        userId: userId.trim(),
        userNm: userNm.trim(),
        srchCondition: srvcSttus,
        srchData: srchData.trim(),
        startDateString: dateRange.start,
        endDateString: dateRange.end,
      };
      await getUserList(params)
        .then(res => {
          const { rows, totalRecords, totalPages } = res.data;
          this.tableItems = rows;
          this.totalRecords = totalRecords;
          this.totalPages = totalPages;
        })
        .catch(error => {
          console.log('error', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // row 클릭 시 상세 조회
    async rowClick(item) {
      this.tabOverlay = true;
      await getUserDetail(item).then(({ data }) => {
        const keys = Object.keys(this.tabItems[0].selectedItem);
        keys.forEach(key => {
          let detailVal;
          switch (key) {
            case 'serviceVO':
              detailVal = this.getServiceTxt(data[key]);
              break;
            case 'userBrthdy':
              detailVal = this.getFormatDate(data[key]);
              break;
            case 'mrrgAnnvrsry':
              detailVal = this.getFormatDate(data[key]);
              break;
            case 'userMoblphon':
              detailVal = this.getPhoneNoText(data[key]);
              break;
            case 'userCbleTlphon':
              detailVal = this.getPhoneNoText(data[key]);
              break;
            case 'idLosQestn':
              detailVal = data[key] + ' / ' + data['idLosAnswer'];
              break;
            default:
              detailVal = data[key];
          }
          this.tabItems[0].selectedItem[key].value = detailVal;
        });
      });
      this.tabOverlay = false;
    },
    // 상세정보 서비스 값 format
    getServiceTxt(data) {
      let userServices = '';
      if (data && data.length > 0) {
        data.forEach(srvcVal => {
          userServices += srvcVal.srvcNm + '(' + srvcVal.srvcId + ')</br>';
        });
      }
      userServices =
        '<div style="height:60px; overflow-y:auto;">' + userServices;
      return userServices;
    },
    // 검색
    keyUpEnter() {
      this.$refs.tableListComponent.search();
    },
  },
  computed: {
    itemsWithIndex() {
      return this.tableItems.map((tableItems, index) => ({
        ...tableItems,
        srbdeDt: this.getFormatDate(this.tableItems[index].srbdeDt),
      }));
    },
    leftPanelTitle() {
      return this.$i18n.t('service.user.leftPanel.title');
    },
    headers() {
      return [
        {
          text: this.$i18n.t('service.user.leftPanel.table-column1'),
          value: 'userId',
          width: '25%',
        },
        {
          text: this.$i18n.t('service.user.leftPanel.table-column2'),
          value: 'userNm',
          width: '25%',
        },
        {
          text: this.$i18n.t('service.user.leftPanel.table-column3'),
          value: 'srvcNm',
          width: '15%',
        },
        {
          text: this.$i18n.t('service.user.leftPanel.table-column4'),
          value: 'srvcId',
          width: '15%',
        },
        {
          text: this.$i18n.t('service.user.leftPanel.table-column5'),
          value: 'srbdeDt',
          width: '20%',
        },
      ];
    },
    rightPanelTitle() {
      return this.$i18n.t('service.user.rightPanel.title');
    },
    tabItems() {
      return [
        {
          headers: [
            {
              text: this.$i18n.t('service.user.rightPanel.header1'),
              width: '40%',
            },
            {
              text: this.$i18n.t('service.user.rightPanel.header2'),
              width: '60%',
            },
          ],
          selectedItem: {
            userId: {
              name: this.$i18n.t('service.user.rightPanel.label1'),
              value: '',
            },
            userNm: {
              name: this.$i18n.t('service.user.rightPanel.label2'),
              value: '',
            },
            serviceVO: {
              name: this.$i18n.t('service.user.rightPanel.label3'),
              value: '',
            },
            userBrthdy: {
              name: this.$i18n.t('service.user.rightPanel.label4'),
              value: '',
            },
            mrrgAnnvrsry: {
              name: this.$i18n.t('service.user.rightPanel.label5'),
              value: '',
            },
            userSexdstn: {
              name: this.$i18n.t('service.user.rightPanel.label6'),
              value: '',
            },
            userEmail: {
              name: this.$i18n.t('service.user.rightPanel.label7'),
              value: '',
            },
            userMoblphon: {
              name: this.$i18n.t('service.user.rightPanel.label8'),
              value: '',
            },
            userCbleTlphon: {
              name: this.$i18n.t('service.user.rightPanel.label9'),
              value: '',
            },
            userZip: {
              name: this.$i18n.t('service.user.rightPanel.label10'),
              value: '',
            },
            userAdres: {
              name: this.$i18n.t('service.user.rightPanel.label11'),
              value: '',
            },
            userDetailAdres: {
              name: this.$i18n.t('service.user.rightPanel.label12'),
              value: '',
            },
            idLosQestn: {
              name: this.$i18n.t('service.user.rightPanel.label13'),
              value: '',
            },
            fhirResrce: {
              name: this.$i18n.t('service.user.rightPanel.label14'),
              value: '',
            },
            srbdeDt: {
              name: this.$i18n.t('service.user.rightPanel.label15'),
              value: '',
            },
          },
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped></style>
