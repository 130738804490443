<template>
  <!-- 수정 팝업 -->
  <div class="pop" :style="isOpen ? 'display:block' : 'display:none'">
    <div class="popup-mask"></div>
    <div class="pop-content pop-ani pop-drag" style="width:500px">
      <div class="pop-head">
        <h2>{{ $t('service.management.updateServicePopUp.title') }}</h2>
        <button class="btn-x" @click="doClose()"></button>
      </div>
      <div class="pop-body">
        <validation-observer ref="updateServiceValidate">
          <form>
            <table class="table-style-default table-pop">
              <colgroup>
                <col width="140px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <th>{{ serviceId }}</th>
                  <td>
                    <validate-form
                      :label="serviceId"
                      :rules="{
                        required: true,
                        min: 4,
                        max: 20,
                        regex: /^[0-9A-Za-z_-]{4,20}$/,
                      }"
                      :errorData="serviceIdInputErr"
                      inputClass="input-24 w-100"
                      v-model="updateSrvc.srvcId"
                      maxlength="20"
                      :disabled="true"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ serviceName }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      :label="serviceName"
                      :rules="{ required: true, min: 4, max: 30 }"
                      inputClass="input-24 w-100"
                      v-model="updateSrvc.srvcNm"
                      maxlength="30"
                      @keydown="inputLiteralCheck"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ redirectUri }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      :label="redirectUri"
                      :rules="{
                        required: true,
                        max: 255,
                        regex: /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                      }"
                      :errorData="urlInputErr"
                      inputClass="input-24 w-100"
                      :placeholder="urlPlaceholder"
                      v-model="updateSrvc.webServerRedirectUri"
                      maxlength="255"
                      dataType="URL"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ userRegistAlarmUri }}</th>
                  <td>
                    <validate-form
                      :label="userRegistAlarmUri"
                      :rules="{
                        max: 255,
                        regex: /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                      }"
                      :errorData="urlInputErr"
                      inputClass="input-24 w-100"
                      :placeholder="urlPlaceholder"
                      v-model="updateSrvc.mberRegistNtcnUrl"
                      maxlength="255"
                      dataType="URL"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ serviceApprovalAlarmUri }}</th>
                  <td>
                    <validate-form
                      :label="serviceApprovalAlarmUri"
                      :rules="{
                        max: 255,
                        regex: /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                      }"
                      :errorData="urlInputErr"
                      inputClass="input-24 w-100"
                      :placeholder="urlPlaceholder"
                      v-model="updateSrvc.srvcConfmNtcnUrl"
                      maxlength="255"
                      dataType="URL"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ pushUrl }}</th>
                  <td>
                    <validate-form
                      :label="pushUrl"
                      :rules="{
                        max: 255,
                        regex: /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                      }"
                      :errorData="urlInputErr"
                      inputClass="input-24 w-100"
                      :placeholder="urlPlaceholder"
                      v-model="updateSrvc.pushRedirectUrl"
                      maxlength="255"
                      dataType="URL"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ feedUrl }}</th>
                  <td>
                    <validate-form
                      :label="feedUrl"
                      :rules="{
                        max: 255,
                        regex: /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                      }"
                      :errorData="urlInputErr"
                      inputClass="input-24 w-100"
                      :placeholder="urlPlaceholder"
                      v-model="updateSrvc.feedRedirectUrl"
                      maxlength="255"
                      dataType="URL"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ companyName }}</th>
                  <td>
                    <validate-form
                      :label="companyName"
                      :rules="{ max: 25 }"
                      inputClass="input-24 w-100"
                      v-model="updateSrvc.cmpnyNm"
                      maxlength="25"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ adminTel }}</th>
                  <td>
                    <validate-form
                      type="number"
                      :label="adminTel"
                      :rules="{ max: 11, regex: /^\d{0,30}$/ }"
                      :errorData="telInputErr"
                      inputClass="input-24 w-100"
                      :placeholder="telPlaceholder"
                      v-model="updateSrvc.chargerTelno"
                      maxlength="11"
                      @keydown="inputNumberCheck"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ adminEmail }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      :label="adminEmail"
                      :rules="{ required: true, max: 50, email: true }"
                      inputClass="input-24 w-100"
                      v-model="updateSrvc.chargerEmail"
                      maxlength="50"
                      dataType="EMAIL"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ approvalStatus }}<i class="star">*</i></th>
                  <td>
                    <select
                      class="input-24"
                      name="u_srvcSttus"
                      style="width:100px"
                      v-model="updateSrvc.srvcSttus"
                    >
                      <option
                        v-for="srvcSttusCode in srvcSttusCodes"
                        :key="srvcSttusCode.codeId"
                        :value="srvcSttusCode.codeId"
                      >
                        {{ srvcSttusCode.codeName }}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>{{ description }}</th>
                  <td>
                    <validate-form
                      type="textarea"
                      :label="description"
                      rows="5"
                      :rules="{ max: 300 }"
                      inputClass="input-24 w-100"
                      v-model="updateSrvc.dc"
                      maxlength="300"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </validation-observer>
        <p class="pop-btn-group">
          <input
            type="button"
            :value="btnSubmit"
            class="bt-line30 cr-orange"
            @click="doUpdateService()"
          />
          <input
            type="button"
            :value="btnCancel"
            class="bt-line30"
            @click="doClose()"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CommonMixins from '@/mixins/CommonMixins';
import ValidateForm from '@/components/ValidateForm.vue';
import { updateService } from '@/api/serviceApi';

export default {
  props: {
    srvcSttusCodes: {
      type: Array,
      required: true,
    },
    serviceDetail: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      updateSrvc: {
        srvcId: '',
        srvcNm: '',
        webServerRedirectUri: '',
        mberRegistNtcnUrl: '',
        srvcConfmNtcnUrl: '',
        pushRedirectUrl: '',
        feedRedirectUrl: '',
        cmpnyNm: '',
        chargerTelno: '',
        chargerEmail: '',
        srvcSttus: '',
        oldSrvcSttus: '',
        oldWebServerRedirectUri: '',
        dc: '',
      },
    };
  },
  mixins: [CommonMixins],
  components: {
    ValidateForm,
  },
  computed: {
    serviceId() {
      return this.$i18n.t('service.management.updateServicePopUp.label1');
    },
    serviceName() {
      return this.$i18n.t('service.management.updateServicePopUp.label2');
    },
    redirectUri() {
      return this.$i18n.t('service.management.updateServicePopUp.label3');
    },
    userRegistAlarmUri() {
      return this.$i18n.t('service.management.updateServicePopUp.label4');
    },
    serviceApprovalAlarmUri() {
      return this.$i18n.t('service.management.updateServicePopUp.label5');
    },
    pushUrl() {
      return this.$i18n.t('service.management.updateServicePopUp.label6');
    },
    feedUrl() {
      return this.$i18n.t('service.management.updateServicePopUp.label7');
    },
    companyName() {
      return this.$i18n.t('service.management.updateServicePopUp.label8');
    },
    adminTel() {
      return this.$i18n.t('service.management.updateServicePopUp.label9');
    },
    adminEmail() {
      return this.$i18n.t('service.management.updateServicePopUp.label10');
    },
    approvalStatus() {
      return this.$i18n.t('service.management.updateServicePopUp.label11');
    },
    description() {
      return this.$i18n.t('service.management.updateServicePopUp.label12');
    },
    btnSubmit() {
      return this.$i18n.t('common.btn.submit');
    },
    btnCancel() {
      return this.$i18n.t('common.btn.cancel');
    },
    urlPlaceholder() {
      return this.$i18n.t('common.placeholder.url-format');
    },
    telPlaceholder() {
      return this.$i18n.t('common.placeholder.tel-format');
    },
    serviceIdInputErr() {
      return {
        regex: this.$i18n.t('common.validate.id-format'),
      };
    },
    urlInputErr() {
      return {
        regex: this.$i18n.t('common.validate.url-format'),
      };
    },
    telInputErr() {
      return {
        regex: this.$i18n.t('common.validate.tel-format'),
      };
    },
  },
  methods: {
    doClose() {
      this.$refs.updateServiceValidate.reset();
      this.setUpdateSrvc();
      this.$emit('close', false);
    },
    setUpdateSrvc() {
      this.updateSrvc.srvcId = this.serviceDetail.srvcId;
      this.updateSrvc.srvcNm = this.serviceDetail.srvcNm;
      this.updateSrvc.webServerRedirectUri = this.serviceDetail.webServerRedirectUri;
      this.updateSrvc.mberRegistNtcnUrl = this.serviceDetail.mberRegistNtcnUrl;
      this.updateSrvc.srvcConfmNtcnUrl = this.serviceDetail.srvcConfmNtcnUrl;
      this.updateSrvc.pushRedirectUrl = this.serviceDetail.pushRedirectUrl;
      this.updateSrvc.feedRedirectUrl = this.serviceDetail.feedRedirectUrl;
      this.updateSrvc.cmpnyNm = this.serviceDetail.cmpnyNm;
      this.updateSrvc.chargerTelno = this.serviceDetail.chargerTelno;
      this.updateSrvc.chargerEmail = this.serviceDetail.chargerEmail;
      this.updateSrvc.srvcSttus = this.serviceDetail.srvcSttus;
      this.updateSrvc.oldSrvcSttus = this.serviceDetail.srvcSttus;
      this.updateSrvc.oldWebServerRedirectUri = this.serviceDetail.webServerRedirectUri;
      this.updateSrvc.dc = this.serviceDetail.dc;
    },
    resetUpdateSrvc() {
      Object.keys(this.updateSrvc).map(k => (this.updateSrvc[k] = ''));
    },
    async doUpdateService() {
      const result = await this.$refs.updateServiceValidate.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return false;
      }

      const reqObj = this.updateSrvc;

      try {
        const response = await updateService(reqObj);
        console.log('response', response);
        const { valid } = response.data;
        if (!valid) {
          this.$dialogs.alert(this.$i18n.t('common.alert.update-fail'));
          return;
        }

        this.$dialogs
          .alert(this.$i18n.t('common.alert.update-success'))
          .then(() => {
            this.doClose();
            this.$emit('serviceUpdated');
          });
      } catch (error) {
        console.log('error', error);
      }
    },
  },
  watch: {
    serviceDetail() {
      if (!this.isEmptyObject(this.serviceDetail)) {
        this.setUpdateSrvc();
      } else {
        this.resetUpdateSrvc();
      }
    },
  },
};
</script>

<style></style>
