<template>
  <section>
    <two-panel
      :leftPanelWidth="'1050'"
      :rightPanelWidth="'337'"
      :leftPanelTitle="leftPanelTitle"
      :rightPanelTitle="rightPanelTitle"
    >
      <template #leftPanel>
        <!-------------- 앱 목록 -------------->
        <table-list-component
          :tableHeight="'603'"
          ref="tableListComponent"
          :headers="headers"
          :items="tableItems"
          :nowPerPage="nowPerPage"
          :totalRecords="totalRecords"
          :totalPages="totalPages"
          :searchOpts="searchOpts"
          :customSlotInfo="customSlotInfo"
          :reloadToggle="reloadToggle"
          :loading="loading"
          @rowClick="rowClick"
          @getDataFromApi="getAppSrchList"
        >
          <!-------------- 검색 옵션 -------------->
          <template #searchOptions>
            <label class="sort-label"
              >· {{ $t('service.app.search-condition1') }}</label
            >
            <input
              v-model="searchOpts.appId"
              type="text"
              class="sort-input-text"
              maxlength="20"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />
            <label class="sort-label"
              >· {{ $t('service.app.search-condition2') }}</label
            >
            <input
              v-model="searchOpts.appNm"
              type="text"
              class="sort-input-text"
              maxlength="30"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />
            <label class="sort-label"
              >· {{ $t('service.app.search-condition3') }}
            </label>
            <select
              v-model="searchOpts.osTypeCode"
              class="sort-input-text"
              style="width:100px;"
            >
              <option :value="''">{{ $t('common.all') }}</option>
              <option :value="'Android'">Android</option>
              <option :value="'iOS'">iOS</option>
              <option :value="'Windows'">Windows</option>
            </select>
          </template>
          <!-------------- subHeaderItem -------------->
          <template #subHeaderItem>
            <input
              type="button"
              :value="btnRegister"
              class="bt-line24 cr-orange"
              @click="openPopup('insert')"
            />
          </template>
          <!-- customSlot -->
          <template v-slot:enfrcUpdtAt_custom="{ item }">
            <td
              class="text-center"
              :style="{ color: getColor(item.enfrcUpdtAt) }"
            >
              {{ item.enfrcUpdtAt }}
            </td>
          </template>
          <template v-slot:creatDt_custom="{ item }">
            <td class="text-center">
              {{ getFormatDate(item.creatDt) }}
            </td>
          </template>
          <!-- 길이 초과될 거 같은 컬럼의 경우, title 입력.. mouseOver 시 텍스트 표시됨 -->
          <template v-slot:srvcNm_custom="{ item }">
            <td class="text-center" :title="item.srvcNm">
              {{ item.srvcNm }}
            </td>
          </template>
        </table-list-component>
      </template>
      <!-------------- 상세 정보 -------------->
      <template #rightPanel>
        <table-tab-component
          :tabItems="tabItems"
          :tabOverlay="tabOverlay"
          :tableHeight="'710'"
        >
          <!-- custom slot -->
          <template v-slot:enfrcUpdtAt_tab_custom="{ item }">
            <td :style="{ color: getColor(item.value) }">
              {{ item.value || '-' }}
            </td>
          </template>
          <template v-slot:creatDt_tab_custom="{ item }">
            <td>{{ item.value ? getFormatDate(item.value) : '-' }}</td>
          </template>
          <template v-slot:updtDt_tab_custom="{ item }">
            <td>{{ item.value ? getFormatDate(item.value) : '-' }}</td>
          </template>

          <!-- bottom에 버튼 -->
          <template #appInfo_bottom_button>
            <div class="table-bot-btns">
              <p class="f-right">
                <input
                  type="button"
                  id="button_update_app"
                  :value="btnUpdate"
                  class="bt-line24 cr-gray"
                  @click="openPopup('update')"
                />
                <input
                  type="button"
                  id="button_delete_app"
                  :value="btnDelete"
                  class="bt-line24 cr-gray"
                  @click="goDeleteApp"
                />
              </p>
            </div>
          </template>
        </table-tab-component>
      </template>
    </two-panel>
    <!---------------------------- 앱 팝업 ---------------------------->
    <div class="pop-drag" v-if="popup.show">
      <div class="popup-mask"></div>
      <div class="pop-content pop-ani pop-drag" style="width:500px">
        <div class="pop-head">
          <h2>{{ popup.title }}</h2>
          <button class="btn-x" @click="closePopup"></button>
        </div>
        <div class="pop-body">
          <validation-observer ref="observer">
            <form>
              <table class="table-style-default table-pop">
                <colgroup>
                  <col width="106px" />
                  <col width="*" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>{{ appId }}<i class="star">*</i></th>
                    <td v-if="popup.type == 'update'">
                      <validate-form
                        :label="appId"
                        v-model="popup.appItem.appId"
                        name="appId"
                        :rules="{
                          required: true,
                          max: 20,
                        }"
                        maxlength="20"
                        inputClass="input-24 w-100"
                        :readonly="true"
                      />
                    </td>
                    <td v-else>
                      <validate-form
                        :label="appId"
                        v-model="popup.appItem.appId"
                        name="appId"
                        :rules="{
                          required: true,
                          max: 20,
                        }"
                        maxlength="20"
                        inputClass="input-24 w-100"
                        @keydown="inputLiteralCheck"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ appName }}<i class="star">*</i></th>
                    <td v-if="popup.type == 'update'">
                      <validate-form
                        :label="appName"
                        v-model="popup.appItem.appNm"
                        :rules="{
                          required: true,
                          max: 200,
                        }"
                        maxlength="200"
                        inputClass="input-24 w-100"
                        :readonly="true"
                      />
                    </td>
                    <td v-else>
                      <validate-form
                        :label="appName"
                        v-model="popup.appItem.appNm"
                        :rules="{
                          required: true,
                          max: 200,
                        }"
                        maxlength="200"
                        inputClass="input-24 w-100"
                        @keydown="inputLiteralCheck"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ osType }}<i class="star">*</i></th>
                    <td v-if="popup.type == 'update'">
                      <select
                        v-model="popup.appItem.osTypeCode"
                        class="input-24"
                        style="width:100px"
                        :readonly="true"
                        :disabled="true"
                      >
                        <option :value="'Android'">Android</option>
                        <option :value="'iOS'">iOS</option>
                        <option :value="'Windows'">Windows</option>
                      </select>
                    </td>
                    <td v-else>
                      <select
                        v-model="popup.appItem.osTypeCode"
                        class="input-24"
                        style="width:100px"
                      >
                        <option :value="'Android'">Android</option>
                        <option :value="'iOS'">iOS</option>
                        <option :value="'Windows'">Windows</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ appVer }}<i class="star">*</i></th>
                    <td>
                      <validate-form
                        :label="appVer"
                        v-model="popup.appItem.appVerVal"
                        :rules="{
                          required: true,
                          max: 100,
                        }"
                        maxlength="100"
                        inputClass="input-24 w-100"
                        @keydown="inputLiteralCheck"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ downloadUri }}</th>
                    <td>
                      <validate-form
                        :label="downloadUri"
                        v-model="popup.appItem.dwldUrl"
                        :rules="{
                          max: 300,
                          regex: /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                        }"
                        :errorData="urlInputErr"
                        maxlength="300"
                        inputClass="input-24 w-100"
                        :placeholder="urlPlaceholder"
                        dataType="URL"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ resetYn }}<i class="star">*</i></th>
                    <td>
                      <validate-form
                        type="checkbox"
                        v-model="popup.appItem.enfrcUpdtAt"
                        @click="enfrcUpdtAtChk"
                        :checked="
                          popup.appItem.enfrcUpdtAt == 'Y' ? true : false
                        "
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
            <p class="pop-btn-group">
              <input
                type="button"
                :value="popup.type == 'insert' ? btnRegister : btnSubmit"
                class="bt-line30 cr-orange"
                @click="popup.type == 'insert' ? goInsertApp() : goUpdateApp()"
              />
              <input
                type="button"
                :value="btnCancel"
                class="bt-line30"
                @click="closePopup"
              />
            </p>
          </validation-observer>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import TwoPanel from '@/layouts/TwoPanel.vue';
import TableListComponent from '@/components/TableListComponent';
import TableTabComponent from '@/components/TableTabComponent';
import mixins from '@/mixins/CommonMixins';
import ValidateForm from '@/components/ValidateForm.vue';
import {
  getAppList,
  getAppDetail,
  insertApp,
  updateApp,
  deleteApp,
} from '@/api/serviceApi';

export default {
  mixins: [mixins],
  components: {
    TwoPanel,
    TableListComponent,
    TableTabComponent,
    ValidateForm,
  },
  data: () => ({
    // --- 테이블 --- //
    loading: false,
    // --- 검색 정보 --- //
    searchOpts: {
      appId: '',
      appNm: '',
      osTypeCode: '',
      // sidx: null,
      // sord: null,
    },
    // --- Total row 개수 --- //
    totalRecords: 0,
    totalPages: 0,
    tableItems: [],
    nowPerPage: 30,
    reloadToggle: true,
    // --- custom Slot --- //
    customSlotInfo: [
      { name: 'srvcNm', slotName: 'item.srvcNm' },
      { name: 'enfrcUpdtAt', slotName: 'item.enfrcUpdtAt' },
      { name: 'creatDt', slotName: 'item.creatDt' },
      { name: 'updtDt', slotName: 'item.updtDt' },
    ],
    // -- 우측 패널 테이블 -- //
    tabOverlay: false,
    tabItems: [],
    // -- 팝업 -- //
    popup: {
      type: '',
      show: false,
      appItem: {
        appSn: '',
        appId: '',
        appNm: '',
        osTypeCode: 'Android',
        appVerVal: '',
        dwldUrl: '',
        enfrcUpdtAt: 'N',
      },
    },
  }),
  created() {
    this.tabItems = this.tabItemsComputed;
  },
  computed: {
    leftPanelTitle() {
      return this.$i18n.t('service.app.leftPanel.title');
    },
    headers() {
      return [
        {
          text: this.$i18n.t('service.app.leftPanel.table-column1'),
          value: 'appSn',
          width: '10%',
        },
        {
          text: this.$i18n.t('service.app.leftPanel.table-column2'),
          value: 'appId',
          width: '20%',
        },
        {
          text: this.$i18n.t('service.app.leftPanel.table-column3'),
          value: 'appNm',
          width: '20%',
        },
        {
          text: this.$i18n.t('service.app.leftPanel.table-column4'),
          value: 'osTypeCode',
          width: '10%',
        },
        {
          text: this.$i18n.t('service.app.leftPanel.table-column5'),
          value: 'appVerVal',
          width: '10%',
        },
        {
          text: this.$i18n.t('service.app.leftPanel.table-column6'),
          value: 'enfrcUpdtAt',
          isCustom: true,
          width: '14%',
        },
        {
          text: this.$i18n.t('service.app.leftPanel.table-column7'),
          value: 'creatDt',
          width: '16%',
        },
      ];
    },
    rightPanelTitle() {
      return this.$i18n.t('service.app.rightPanel.title');
    },
    tabItemsComputed() {
      return [
        {
          id: 'appInfo',
          title: this.$i18n.t('service.app.rightPanel.title'),
          headers: [
            {
              text: this.$i18n.t('service.app.rightPanel.header1'),
              width: '40%',
            },
            {
              text: this.$i18n.t('service.app.rightPanel.header2'),
              width: '60%',
            },
          ],
          selectedItem: {
            appSn: {
              name: this.$i18n.t('service.app.rightPanel.label1'),
              value: '',
            },
            appId: {
              name: this.$i18n.t('service.app.rightPanel.label2'),
              value: '',
            },
            appNm: {
              name: this.$i18n.t('service.app.rightPanel.label3'),
              value: '',
            },
            osTypeCode: {
              name: this.$i18n.t('service.app.rightPanel.label4'),
              value: '',
            },
            appVerVal: {
              name: this.$i18n.t('service.app.rightPanel.label5'),
              value: '',
            },
            dwldUrl: {
              name: this.$i18n.t('service.app.rightPanel.label6'),
              value: '',
            },
            enfrcUpdtAt: {
              name: this.$i18n.t('service.app.rightPanel.label7'),
              value: '',
              isCustom: true,
            },
            creatDt: {
              name: this.$i18n.t('service.app.rightPanel.label8'),
              value: '',
              isCustom: true,
            },
            updtDt: {
              name: this.$i18n.t('service.app.rightPanel.label9'),
              value: '',
              isCustom: true,
            },
          },
        },
      ];
    },
    appId() {
      return this.$i18n.t('service.app.popUp.label1');
    },
    appName() {
      return this.$i18n.t('service.app.popUp.label2');
    },
    osType() {
      return this.$i18n.t('service.app.popUp.label3');
    },
    appVer() {
      return this.$i18n.t('service.app.popUp.label4');
    },
    downloadUri() {
      return this.$i18n.t('service.app.popUp.label5');
    },
    resetYn() {
      return this.$i18n.t('service.app.popUp.label6');
    },
    urlPlaceholder() {
      return this.$i18n.t('common.placeholder.url-format');
    },
    urlInputErr() {
      return {
        regex: this.$i18n.t('common.validate.url-format'),
      };
    },
    btnRegister() {
      return this.$i18n.t('common.btn.register');
    },
    btnUpdate() {
      return this.$i18n.t('common.btn.update');
    },
    btnDelete() {
      return this.$i18n.t('common.btn.delete');
    },
    btnSubmit() {
      return this.$i18n.t('common.btn.submit');
    },
    btnCancel() {
      return this.$i18n.t('common.btn.cancel');
    },
    lang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    lang() {
      this.tabItems = this.tabItemsComputed;
    },
  },
  methods: {
    // 앱 목록 조회
    async getAppSrchList(searchParams) {
      this.loading = true;
      const { rows, page, sord, sidx, appNm, appId, osTypeCode } = searchParams;
      const params = {
        // 리스트 표출건수
        rows,
        // 요청페이지
        page,
        // 정렬 컬럼
        sidx,
        // 정렬
        sord,
        appNm: appNm.trim(),
        appId: appId.trim(),
        osTypeCode: osTypeCode,
      };
      await getAppList(params)
        .then(res => {
          const { rows, totalRecords, totalPages } = res.data;
          this.tableItems = rows;
          this.totalRecords = totalRecords;
          this.totalPages = totalPages;
        })
        .catch(error => {
          console.log('error', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 강제 갱신 여부 색상
    getColor(enfrcUpdtAt) {
      if (enfrcUpdtAt === 'Y') return '#38A1D1';
      else if (enfrcUpdtAt === 'N') return '#F25555';
      else return '#F25555';
    },
    // row 클릭 시 상세 조회
    async rowClick(item) {
      this.tabOverlay = true;
      await getAppDetail(item.appSn).then(({ data }) => {
        const keys = Object.keys(this.tabItems[0].selectedItem);
        keys.forEach(key => {
          this.tabItems[0].selectedItem[key].value = data[key];
        });
      });
      this.tabOverlay = false;
    },
    // 검색
    keyUpEnter() {
      this.$refs.tableListComponent.search();
    },

    // 팝업
    openPopup(type) {
      this.popup.type = type;
      if (type == 'insert') {
        this.popup.title = this.$i18n.t('service.app.popUp.title-insert');
      } else if (type == 'update') {
        const selectedItem = this.tabItems[0].selectedItem;
        this.popup.title = this.$i18n.t('service.app.popUp.title-update');
        if (!selectedItem.appSn.value) {
          const target = this.getPostWord(
            this.$i18n.t('service.app.target-app'),
            '을',
            '를',
          );
          this.$dialogs.alert(
            this.$i18n.t('common.alert.target-update', { target }),
          );
          return;
        }
        Object.keys(this.popup.appItem).forEach(key => {
          this.popup.appItem[key] = selectedItem[key]?.value;
        });
      }
      this.popup.show = true;
    },
    // 팝업 닫기
    closePopup() {
      this.popup.show = false;
      Object.keys(this.popup.appItem).forEach(key => {
        switch (key) {
          case 'enfrcUpdtAt':
            this.popup.appItem[key] = 'N';
            break;
          case 'osTypeCode':
            this.popup.appItem[key] = 'Android';
            break;
          default:
            this.popup.appItem[key] = '';
        }
      });
    },

    // 앱 등록
    async goInsertApp() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return;
      }
      // 모든 value trim
      Object.keys(this.popup.appItem).forEach(
        k => (this.popup.appItem[k] = String(this.popup.appItem[k]).trim()),
      );

      // 비밀번호 체크
      const passwordChk = await this.$dialogs.password();
      if (passwordChk.ok) {
        try {
          await insertApp(this.popup.appItem).then(({ data }) => {
            if (data.valid) {
              this.$dialogs
                .alert(this.$i18n.t('common.alert.insert-success'))
                .then(() => {
                  this.closePopup();
                  this.reloadToggle = !this.reloadToggle;
                  this.clearTabItems();
                });
            } else {
              this.$dialogs.alert(this.$i18n.t('common.alert.insert-fail'));
            }
          });
        } catch (error) {
          console.log('error', error);
        }
      }
    },
    // 앱 수정
    async goUpdateApp() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return;
      }
      // 비밀번호 체크
      const passwordChk = await this.$dialogs.password();
      // 선택된 row의 appSn
      const appSn = this.tabItems[0].selectedItem.appSn.value;
      if (passwordChk.ok) {
        try {
          await updateApp(this.popup.appItem).then(({ data }) => {
            if (data.valid) {
              this.$dialogs
                .alert(this.$i18n.t('common.alert.update-success'))
                .then(() => {
                  this.closePopup();
                  this.reloadToggle = !this.reloadToggle;
                  this.rowClick({ appSn });
                });
            } else {
              this.$dialogs.alert(this.$i18n.t('common.alert.update-fail'));
            }
          });
        } catch (error) {
          console.log('error', error);
        }
      }
    },

    // 앱 삭제
    async goDeleteApp() {
      const selAppSn = this.tabItems[0].selectedItem.appSn.value;
      if (!selAppSn) {
        const target = this.getPostWord(
          this.$i18n.t('service.app.target-app'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-delete', { target }),
        );
        return;
      }

      this.$dialogs
        .confirm(
          `<p>${this.$i18n.t(
            'service.app.target-appName',
          )} : <span class="c-orange">${
            this.tabItems[0].selectedItem.appNm.value
          } (${
            this.tabItems[0].selectedItem.appId.value
          })</span></p>${this.$i18n.t('common.alert.delete-confirm')}`,
        )
        .then(async ({ ok }) => {
          if (ok) {
            try {
              await deleteApp(selAppSn).then(({ data }) => {
                if (data.valid) {
                  this.$dialogs.alert(
                    this.$i18n.t('common.alert.delete-success'),
                  );
                  this.closePopup();
                  this.clearTabItems();
                  this.reloadToggle = !this.reloadToggle;
                } else {
                  this.$dialogs.alert(this.$i18n.t('common.alert.delete-fail'));
                }
              });
            } catch (error) {
              console.log(error);
            }
          }
        });
    },

    // 강제 갱신 여부 체크
    enfrcUpdtAtChk() {
      const chkVal = this.popup.appItem['enfrcUpdtAt'];
      if (chkVal == 'Y') {
        this.popup.appItem['enfrcUpdtAt'] = 'N';
      } else {
        this.popup.appItem['enfrcUpdtAt'] = 'Y';
      }
    },

    // 우측 패널 데이터 초기화
    clearTabItems() {
      //  선택 정보 clear
      Object.keys(this.tabItems[0].selectedItem).forEach(key => {
        this.tabItems[0].selectedItem[key].value = '';
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
